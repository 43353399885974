@import url(https://fonts.googleapis.com/css?family=Open+Sans|Indie+Flower);
section {
  overflow-x: hidden;
  padding: 10px 20%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.App {
  text-align: center;
}

header {
  text-align: left;
  padding: 15px 5%;
  background-color: darkcyan;
  color: white;
}

.App-intro {
  font-size: large;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
  margin: 0;
  padding: 0;
  overflow: 'hidden',
}

