.App {
  text-align: center;
}

header {
  text-align: left;
  padding: 15px 5%;
  background-color: darkcyan;
  color: white;
}

.App-intro {
  font-size: large;
}
