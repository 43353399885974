@import url(https://fonts.googleapis.com/css?family=Open+Sans|Indie+Flower);

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
  margin: 0;
  padding: 0;
  overflow: 'hidden',
}
